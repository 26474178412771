.navrendpay {
  background-color: white;
  height: 60px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-top: 15px;
  grid-template-areas: "logo msgcon";
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 1000;
}

.div-logo{
  align-items: left;
  grid-area: "logo";
}

.logo-login {
  height: 40px;
}

.icon-div {
  display: inline-flex;
  align-items: center;
  align-content: center;
  padding: 5px;
  height: 30px;
  width: 184px;
  grid-area: "msgcon";
}

/* Conexão privada e ambiente seguro*/
.msgconexao {
  width: 112px;
  height: 30px;
  color: #0a1d6f;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  letter-spacing: 0.1px;
  word-wrap: break-word;

}


.icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.icon-group {
  display: inline-flex;
  align-items: center;
}

@media screen and (max-width: 576px){
  .logo-labs{
    height: 15px;
  }  
  .msgconexao{
    display: none;
  }
}
