
.cartoes {
  color: #737373;
  font-size: 21px;
  font-family: Inter;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.17px;
  word-wrap: break-word;
}


.pre-pago {
  color: #0a1d6f;
  font-size: 21px;
  font-family: Inter;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.17px;
  word-wrap: break-word;
}

.component-container {
  padding-top: 32px;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  grid-area: "component-container";
}

.component-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #54e99c;
  background-color: #f5f5f5;
  margin: 20px;
}

.component-container::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.component-container::-webkit-scrollbar-thumb {
  background-color: #0a1d6f;
}

.cartoes ul li {
  list-style-type: none;
  margin: none;
}

.cartoes::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px FFFFFF;
  background-color: #f5f5f5;
  margin: 20px;
}

.cartoes::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.cartoes::-webkit-scrollbar-thumb {
  background-color: #0a1d6f;
}
/*
.dashboard {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-template-areas: "navdash" "component-container";
  justify-content: space-between;  
  align-content: space-around;
  row-gap: 0px;
  margin-bottom: 30px;
}
*/

@media screen and (max-width: 576px) {
  .navdash{
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%
  }
  .dashboard {
    width: 100%;
    height: 80%;
    justify-content: space-between;  
    align-content: space-around;
    row-gap: 0px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  /* Estilos para dispositivos móveis médios */
  .navdash{
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%
  }
  .dashboard {
    width: 100%;
    height: 80%;   
    justify-content: space-between;  
    align-content: space-around;
    row-gap: 0px;
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  /* Estilos para tablets pequenos */
  .navdash{
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%
  }
  .dashboard {
    width: 100%;
    height: 80%;   
    justify-content: space-between;  
    align-content: space-around;
    row-gap: 0px;
    margin-bottom: 30px;
    overflow: scroll;
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  /* Estilos para tablets grandes / desktops pequenos */
  .navdash {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    grid-area: "navdash";
  }
  .dashboard {
    width: 100%;
    height: 80%;
    display: grid;
    grid-template-columns: 1fr 11fr;
    grid-template-areas: "navdash" "component-container";
    justify-content: space-between;  
    align-content: space-around;
    row-gap: 0px;
    margin-bottom: 72px;
  }
  
}

@media screen and (min-width: 1201px) {
  .navdash {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    grid-area: "navdash";
  }
  .dashboard {
    width: 100%;
    height: 80%;
    display: grid;
    grid-template-columns: 1fr 11fr;
    grid-template-areas: "navdash" "component-container";
    justify-content: space-between;  
    align-content: space-around;
    row-gap: 0px;
    margin-top: 8px;
    margin-bottom: 72px;
  }
  
}