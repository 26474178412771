.limites {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}
.progress-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #54e99c;
  transition: width 0.4s ease;
}

.progress-bar2 {
  height: 100%;
  background-color: #fe8b60;
  transition: width 0.4s ease;
}

.displayLimiteInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.campoValor {
  height: 100%;
  margin-top: 10px;
}

.valorLimite {
  color: #3f3f3f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
  letter-spacing: -0.264px;
}

.labelValorLimite {
  color: #3f3f3f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  letter-spacing: -0.264px;
}
