.divFaturas{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    
}

.listMes{
    width: 100%;
    overflow-x: scroll;
}

.faturaSelecionada{
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    width: 100%;
}