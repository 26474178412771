@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");


body {
  background-color: #f2f3f7;
  font-family: "Inter", sans-serif;
}


.gridMyProduct {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 10px;
  padding: 10px;
}

.product {
  background: rgb(255, 0, 110);
  background: linear-gradient(
    90deg,
    rgba(255, 0, 110, 1) 35%,
    rgba(237, 75, 186, 1) 100%
  );
  color: white;
  border-radius: 20px;
  padding: 30px;
  margin: 5px;
  height: auto;
  width: auto;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);*/
}

.product:hover {
  transform: scale(1.1);
}

.product-name {
  font-weight: bold;
  size: 10px;
}

.gridProduct {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-gap: 10px;
  padding: 10px;
}

.nav {
  color: white;
}

.lancamento {
  /*background: rgb(255,0,110);
    background: linear-gradient(90deg, rgba(255,0,110,1) 35%, rgba(237,75,186,1) 100%);   */
  background-color: black;
  color: black;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  height: auto;
  width: auto;
  text-align: left;
  border: 1px solid gainsboro;

  font-size: 15px;
}

.lancamento-item {
  padding-left: 10px;
  background-color: white;
  border-radius: 10px;
  color: black;
  margin-bottom: 5px;
}

.table-lancamento {
  width: 100%;

  border-collapse: separate;
  border-spacing: 0 15px;
}

.table-header {
  color: #0a1d6f;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  text-align: left;
}

.table-line {
  color: #737373;
  font-size: 12px;
  font-family: Inter;
  font-weight: 400;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  text-align: left;
}

.btn-data {
  width: 100px;
  height: 80px;
  margin: 5px;
  text-align: center;
}

/*
.input {
    border-radius: 10px;
    margin: 10px;
    padding: 12px;
    width: 95%;
    background-color: #f0eeee;
    border: 1px solid #f0eeee;
  }

  .label{
    color: #0a1d6f;
    margin-left: 20px;
    font-weight: bold;
    text-align: left;
  }
  */
