.cartoes{
    overflow-x: scroll;
    height: 700px;
    width: 100%;
}

.cartoes ul{
    margin: 0;
    padding: 0;
}

.cartoes ul li {
    list-style-type: none;
    margin: none;
    padding: 12px;
}

