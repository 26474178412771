.form-limite {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  padding: 30px;
}

.limiteInput {
  background: none;
  border: none;
  border-bottom: 1px solid #737373;
  height: 50px;
  width: 150px;
  text-align: center;
  color: #0a1d6f;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.144px;
}

.limit-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 20px;
}

.limit-slider:hover {
  opacity: 1;
}

.limit-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #065538;
  cursor: pointer;
  border-radius: 50%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.limit-slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: #04aa6d;
  cursor: pointer;
}

.displayInfoLimite {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
