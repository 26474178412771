.credit-card {
  padding: 20px;
  color: #737373;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
  display: inline-block;
  text-align: center;
}

.credit-card-number {
  margin-top: 20px;
  letter-spacing: 2px;
}

.credit-card-info {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.botoes-cartao li {
  display: inline;
}

.cardbody {
  display: inline-flex;
}

.desenhoCartao {
  background-image: url(../../assets/diplomatic-infinite.png);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 2.735px 5.47px 0px rgba(0, 0, 0, 0.25);
  margin-right: 16px;
  height: 157px;
  width: 100px;
  border-radius: 10px;
}

.chipimg {
  margin-top: 50px;
}

.textTitular {
  color: #3f3f3f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nomeTitular {
  color: #3f3f3f;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cartaoFinal {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.numeroCartao {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.operacoesCartao {
  text-align: left;
  width: 229px;
  transition: .4s;
  
}

.btnsCartao {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  gap:16px;
}

.btnCartao {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 10px;
  background: #fff;
  text-align: center;
  width: 100px;
  cursor: pointer;
}

.hideBtn{
  border: none;
  padding: 3px;
  background: none;
  margin: 5px;
}

.btnCartaoTxt {
  color: var(--Cinza800, #3f3f3f);
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hide {
  display: none;
}

.headerCard{
  display: inline-flex;
}