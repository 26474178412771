:root {
  --background-primary: #54e99c;
  --color-primary: #0a1d6f;
  --background-primary-hover: #0a1d6f;
  --color-primary-hover: #57ffff;
  --background-primary-pressed: #061140;
  --color-primary-pressed: #57ffff;
  --background-primary-disabled: #d9d9d9;
  --color-primary-disabled: #737373;
  --border-primary-secondary-disabled: #d9d9d9;

  --background-secondary: transparent;
  --color-secondary: #0a1d6f;
  --background-secondary-hover: #54e99c;
  --color-secondary-hover: #0a1d6f;
  --border-secondary-hover: #54e99c;
  --background-secondary-pressed: #1dc56e;
  --color-secondary-pressed: #0a1d6f;
  --background-secondary-disabled: transparent;
  --color-secondary-disabled: #737373;
  --border-color-secondary-disabled: #d9d9d9;

  --input-background-color: #f2f3f7;
  --input-border-color: #737373;
  --input-invalid-color: red;
}


.full {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}

.btn {
  height: 44px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 21px;
  word-wrap: break-word;
  margin: none;
  text-align: center;
}

.btn:focus {
  outline: 2px #5271ff solid;
}

.btn-large {
  height: 36px;
}

.btn-small {
  height: 32px;
}

.btn-primary {
  background-color: var(--background-primary);
  color: var(--color-primary);
  border: 1px var(--background-primary) solid;
}

.btn-primary:hover {
  background-color: var(--background-primary-hover);
  color: var(--color-primary-hover);
  border: 1px var(--background-primary-hover) solid;
}

.btn-primary:active {
  background-color: var(--background-primary-pressed);
  color: var(--color-primary-pressed);
  border: 1px var(--background-primary-pressed) solid;
  outline: none;
}

.btn-primary:disabled {
  background-color: var(--background-primary-disabled);
  color: var(--color-primary-disabled);
  border: 1px var(--background-primary-disabled) solid;
}

.btn-secondary {
  background-color: var(--cor-secundaria);
  color: #0a1d6f;
  border: 1px #0a1d6f solid;
}

.btn-secondary:hover {
  background-color: var(--background-secondary-hover);
  color: var(--color-secondary-hover);
  border: 1px var(--border-secondary-hover) solid;
}

.btn-secondary:active {
  background-color: var(--background-secondary-pressed);
  color: var(--color-secondary-pressed);
  border: 1px var(--background-secondary-pressed) solid;
  outline: none;
}

.btn-secondary:disabled {
  background-color: var(--background-secondary-disabled);
  color: var(--color-secondary-disabled);
  border: 1px var(--border-color-secondary-disabled) solid;
}

/* inputs */

.input-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  height: 70px;
  width: 100%;
}

.label {
  color: #737373;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0.11px;
  margin-left: 12px;
  width: 100%;
  text-align: left;
}

.input {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: var(--input-background-color);
  border-radius: 5px;
  border: 1px var(--input-background-color) solid;
  justify-content: center;
  align-items: center;
  display: inline-flex;

  color: #737373;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  letter-spacing: 0.13px;
  word-wrap: break-word;
  width: 100%;
}

.input-invalid {
}
/*
  .input:invalid {
    border: 1px var(--input-invalid-color) solid;
    color: var(--input-invalid-color);
  }
  */

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  width: 16.67px;
  height: 16.67px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #54e99c;
}

input:focus + .slider {
  box-shadow: 0 0 1px #54e99c;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.w100 {
  width: 100%;
}

.form {
  padding: 32px;
  background: white;
  box-shadow: 0px 3.8048782348632812px 7.6097564697265625px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
  width: 100%;
  height: 100%;
}

.centralize {
  justify-content: center;
  align-items: center;
  display: flex;
}
