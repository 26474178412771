/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    --_c:no-repeat radial-gradient(farthest-side,#25b09b 92%,#0000);
    background: 
      var(--_c) top,
      var(--_c) left,
      var(--_c) right,
      var(--_c) bottom;
    background-size: 12px 12px;
    animation: l7 1s infinite;
  }

  .loaderDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

  }
  @keyframes l7 {to{transform: rotate(.5turn)}}